/**
 * Dostupné kontrolery mikroslužby Email
 */
export enum ConstructionsController {
  ConstructionType = 'api/construction-type',
  ConstructionRequestType = 'api/construction-request-type',
  PartNumber = 'api/part-number',
  ProjectNumber = 'api/project-number',
  ConstructionRequests = 'api/constructionrequests',
  Device = 'api/device',
  Risk = 'api/risk',
  TechnologicalCheck = 'api/technological-check',
  TechnologicalCheckState = 'api/technological-check-state',
  Users = 'api/users',
  BillOfMaterial = 'api/billofmaterial',
  BillOfMaterialPrintRequest = 'api/billofmaterial-print-request',
  BillOfMaterialSynchronization = 'api/billofmaterial-synchronization',
  Codebook = 'api/codebook',
  People = 'api/people',
  DrawingDocumentationKind = 'api/drawing-documentation-kind',
  DrawingDocumentationType = 'api/drawing-documentation-type',
  Workflows = 'api/workflows',
  Task = 'api/task',
  DeviceInstance = 'api/device-instance',
  WageClass = 'api/wage-class',
  DataGroups = 'api/data-groups',
  Supplier = 'api/supplier',
}
