import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiServiceName } from '@core/api/enums/controllers';
import { SnackbarService } from '@core/services/snackbar.service';

@Injectable()
export class ValidationErrorInterceptor implements HttpInterceptor {
  private readonly allowedMethods = ['post', 'put', 'delete'];
  private readonly allowedEndpointPrefixes: ApiServiceName[] = [
    ApiServiceName.Technology,
    ApiServiceName.Manufactory,
    ApiServiceName.Machinery,
  ];

  private readonly snackbarService = inject(SnackbarService);

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith('http')) return next.handle(request);
    const url = new URL(request.url);
    const isRequestMethodAllowed = this.allowedMethods.find(p => p === request.method.toLocaleLowerCase());
    const canProcessEndpoint = this.allowedEndpointPrefixes.some(prefix => {
      let pathname = url.pathname;
      // remove api prefix for local routes
      if (url.pathname.startsWith('/api')) pathname = url.pathname.substring(4, url.pathname.length);
      return pathname.startsWith(`/${prefix}`);
    });
    if (isRequestMethodAllowed && canProcessEndpoint) {
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          const errorProperties = err.error?.error_properties;
          const errorMessage = err.error?.error_message;

          if (errorProperties) {
            const msgArr = Object.keys(errorProperties)
              .map((key: string) => errorProperties[key]?.error_message)
              .filter(Boolean);
            msgArr.forEach(msg => {
              this.snackbarService.addMessage({
                message: msg,
                iconType: 'error',
              });
            });

            if (errorMessage && msgArr.length === 0) {
              this.snackbarService.addMessage({
                message: errorMessage,
                iconType: 'error',
              });
            }
          } else if (errorMessage) {
            this.snackbarService.addMessage({
              message: errorMessage,
              iconType: 'error',
            });
          }
          throw err;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
