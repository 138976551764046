import { KeyValue } from '@angular/common';

export enum DataGroup {
  MetallurgicallTools = 'MetallurgicallTools',
  WeldingTools = 'WeldingTools',
  PressTools = 'PressTools',
}

export class DataGroupInfo {
  public static readonly MetallurgicallTools: KeyValue<number, DataGroup> = {
    key: 1,
    value: DataGroup.MetallurgicallTools,
  };
  public static readonly WeldingTools: KeyValue<number, DataGroup> = { key: 2, value: DataGroup.WeldingTools };
  public static readonly PressTools: KeyValue<number, DataGroup> = { key: 3, value: DataGroup.PressTools };

  public static getDataGroupById(id: number): DataGroup | undefined {
    return Object.values(DataGroupInfo).find((p: KeyValue<number, DataGroup>) => p.key === id)?.value;
  }

  public static getIdByDataGroup(dataGroup: DataGroup): number | undefined {
    return Object.values(DataGroupInfo).find((p: KeyValue<number, DataGroup>) => p.value === dataGroup)?.key;
  }
}
