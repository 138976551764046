import { HttpClient } from '@angular/common/http';
import { StringUtils } from '@core/functions/string-utils';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export function addModuleTranslation(translateService: TranslateService, httpClient: HttpClient, modulePathName: string): Observable<void> {
  const currentLang = translateService.currentLang;
  const defaultLang = translateService.defaultLang;
  const hash = environment.production ? environment.appVersion : Date.now();
  const catchErrorCallback = () => {
    return of({});
  };

  const observable$ = [
    httpClient.get(`./assets/i18n/${modulePathName}/${currentLang}.json?t=${hash}`).pipe(catchError(catchErrorCallback)),
  ];

  if (currentLang !== defaultLang) {
    observable$.push(httpClient.get(`./assets/i18n/${modulePathName}/${defaultLang}.json?t=${hash}`).pipe(catchError(catchErrorCallback)));
  }

  return forkJoin(observable$).pipe(
    tap((data: any[]) => {
      if (data[0]) {
        const newTranslations = {
          [StringUtils.toCamelCase(modulePathName)]: data[0],
        };
        translateService.setTranslation(currentLang, newTranslations, true);
      }

      if (data[1]) {
        const newTranslations = {
          [StringUtils.toCamelCase(modulePathName)]: data[1],
        };
        translateService.setTranslation(defaultLang, newTranslations, true);
      }
    }),
    map(() => undefined),
  );
}
