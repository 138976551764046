/**
 * Available controllers for service ToolsManagementDispensing
 */
export enum ToolsManagementDispensingController {
  Atests = 'atests',
  Groups = 'groups',
  Risks = 'risks',
  Employees = 'employees',
  ConsumptionCards = 'consumption-cards',
  Users = 'users',
  HistoryOrders = 'history-orders',
  Causes = 'causes',
  ConsumptionToolTypes = 'consumption-tool-types',
  Baskets = 'baskets',
  BasketsAnonymous = 'baskets-anonymous',
  DispensingPoints = 'dispensing-points',
}
