import { inject, Injectable } from '@angular/core';
import {
  ComponentSettingsType,
  CreateComponentSettingsModel,
  EditComponentSettingModel,
  PatchComponentSettingModel,
} from '@core/api/models/client-storage';
import { UserSettingModel } from '@core/api/models/client-storage/user-setting.model';
// eslint-disable-next-line no-restricted-imports
import { ComponentSettingsDataService } from '@core/api/services/client-storage/component-settings-data.service';
import { UserSettingsDataService } from '@core/api/services/client-storage/user-settings-data.service';
import { forkJoin, map, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsStoreService {
  private readonly _componentSettingsDataService = inject(ComponentSettingsDataService);
  private readonly _usersDataService = inject(UserSettingsDataService);
  private _store: UserSettingModel[] = [];

  public init(): Observable<void> {
    return this._usersDataService.getUserSettings().pipe(
      tap(userSettings => {
        this._store = userSettings;
      }),
      map(() => undefined),
    );
  }

  public create(createSettings: CreateComponentSettingsModel): Observable<UserSettingModel[]> {
    return this._componentSettingsDataService.createComponentSettings(createSettings).pipe(
      switchMap(settingIds => {
        return forkJoin(settingIds.map(settingId => this._componentSettingsDataService.getComponentSettingDetail(settingId.id)));
      }),
      map(settings => {
        const newStoreItems = settings.map<UserSettingModel>(setting => {
          return {
            componentId: createSettings.componentId,
            ...setting,
          };
        });

        this._store.push(...newStoreItems);

        return newStoreItems;
      }),
    );
  }

  public edit(componentId: string, settingId: string, settings: EditComponentSettingModel): Observable<UserSettingModel> {
    return this._componentSettingsDataService.editComponentSettings(settingId, settings).pipe(
      switchMap(settingId => {
        return this._componentSettingsDataService.getComponentSettingDetail(settingId.id);
      }),
      map(setting => {
        const index = this._store.findIndex(storeItem => storeItem.componentId === componentId && storeItem.settingId === settingId);

        const storeItem = this._store[index];
        const updatedStoreItem = {
          ...storeItem,
          ...setting,
        };

        this._store[index] = updatedStoreItem;

        return updatedStoreItem;
      }),
    );
  }

  public patch(componentId: string, id: string, settings: PatchComponentSettingModel): Observable<UserSettingModel> {
    return this._componentSettingsDataService.patchComponentSettings(id, settings).pipe(
      switchMap(settingId => {
        return this._componentSettingsDataService.getComponentSettingDetail(settingId.id);
      }),
      map(setting => {
        const index = this._store.findIndex(storeItem => storeItem.componentId === componentId && storeItem.settingId === id);

        const storeItem = this._store[index];
        const updatedStoreItem = {
          ...storeItem,
          ...setting,
        };

        this._store[index] = updatedStoreItem;

        return updatedStoreItem;
      }),
    );
  }

  public delete(componentId: string, settingIds: string[]): Observable<string[]> {
    return this._componentSettingsDataService.deleteComponentSettings(settingIds).pipe(
      tap(settingIds => {
        settingIds.forEach(settingId => {
          const index = this._store.findIndex(storeItem => storeItem.componentId === componentId && storeItem.settingId === settingId);
          this._store.slice(index, 1);
        });
      }),
    );
  }

  public getSetting(componentId: string, settingId: string): UserSettingModel | undefined {
    return this._store.find(storeItem => storeItem.componentId === componentId && storeItem.settingId === settingId);
  }

  public getSettings(componentId: string): UserSettingModel[] {
    return this._store.filter(storeItem => storeItem.componentId === componentId);
  }

  public getSettingsByType(componentId: string, type: ComponentSettingsType): UserSettingModel[] {
    return this._store.filter(storeItem => storeItem.componentId === componentId && storeItem.typeId === type);
  }

  public getPrimarySettings(componentId: string): UserSettingModel[] {
    return this._store.filter(storeItem => storeItem.componentId === componentId && storeItem.primary);
  }
}
