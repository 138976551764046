/**
 * Dostupné kontrolery mikroslužby ToolsManagementCatalog
 */
export enum ToolsManagementCatalogController {
  Materials = 'materials',
  Suppliers = 'suppliers',
  MainTypes = 'main-types',
  Kinds = 'kinds',
  Shanks = 'shanks',
  Terminologies = 'terminologies',
  Types = 'types',
  ToolSubtypes = 'tool-subtypes',
  DimensionTypes = 'dimension-types',
  Units = 'units',
  Tools = 'tools',
  Groups = 'groups',
  CostCenters = 'cost-centers',
  Products = 'products',
}
