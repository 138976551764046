export enum SupplierManagementController {
  Supplier = 'supplier',
  SupplierDomicile = 'supplier-domicile',
  SupplierType = 'supplier-type',
  SupplierPrimaryCommodity = 'supplier-primary-commodity',
  SupplierCountry = 'supplier-country',
  FrameContract = 'frame-contract',
  FrameContractType = 'frame-contract-type',
  Currency = 'currency',
  User = 'user',
}
