/**
 * Dostupné kontrolery mikroslužby ToolsManagementWarehouse
 */
export enum ToolsManagementWarehouseController {
  StockItems = 'stock-items',
  Warehouses = 'warehouses',
  Movements = 'movements',
  Controls = 'inspections',
  Bins = 'loaded-bins',
  BinTypes = 'bin-types',
  SharpeningOrders = 'sharpening-orders',
  Tools = 'tools',
  External = 'external',
  PurchaseRequest = 'purchase-requests',
  Workflows = 'workflows',
}
