import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AppVersionConfiguration } from '@app/shared/models/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FrontEndDataService {
  private readonly _httpClient = inject(HttpClient);

  public getFrontEndConfig(): Observable<AppVersionConfiguration> {
    return this._httpClient.get<AppVersionConfiguration>('./assets/config.json');
  }
}
