import { AuthModel } from '@core/modules/auth/models';
import { UserDataGroupsModel } from '@core/modules/auth/models/user-data-groups.model';
import { DataGroup } from '@api/models/shared';

export class UserModel extends AuthModel {
  // DZC: proč je zde definováno jako "number"
  id!: string;
  // GUID: internal ID from PPS G2 users
  internalId?: string;
  username?: string;
  password?: string;
  fullname?: string;
  email?: string;
  pic?: string;
  authLevels?: string[];
  roles?: number[];
  occupation?: string;
  companyName?: string;
  phone?: string;
  // personal information
  firstname?: string;
  lastname?: string;
  website?: string;
  // account information
  language?: string;
  timeZone?: string;
  communication?: {
    email: boolean;
    sms: boolean;
    phone: boolean;
  };
  // email settings
  emailSettings?: {
    emailNotification: boolean;
    sendCopyToPersonalEmail: boolean;
    activityRelatesEmail: {
      youHaveNewNotifications: boolean;
      youAreSentADirectMessage: boolean;
      someoneAddsYouAsAsAConnection: boolean;
      uponNewOrder: boolean;
      newMembershipApproval: boolean;
      memberRegistration: boolean;
    };
    updatesFromKeenthemes: {
      newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
      tipsOnGettingMoreOutOfKeen: boolean;
      thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
      newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
      tipsOnMetronicBusinessProducts: boolean;
    };
  };
  dataGroups: UserDataGroupsModel[] = [];

  constructor(user?: any) {
    super(user);
    this.setUser(user);
  }

  setUser(user: any) {
    this.id = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    this.internalId = user.jti || '';
    this.username = user.username || '';
    this.password = user.password || '';
    this.fullname =
      user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'] +
        ' ' +
        user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'] || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/users/default.jpg';
    this.roles = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || '';
    this.occupation = user.occupation || '';
    this.companyName = user.companyName || '';
    this.phone = user.phone || '';
    this.authLevels = user.Levels?.split(',') || [];
    this.dataGroups = user.dataGroups || [];
  }

  public hasAllowedDataGroups(dataGroups: DataGroup[]): boolean {
    return this.dataGroups.some(p => dataGroups.includes(p.dataGroupName)) ?? false;
  }

  public hasAllowedDataGroup(dataGroup: DataGroup): boolean {
    return !!this.dataGroups.find(p => dataGroup === p.dataGroupName);
  }
}
