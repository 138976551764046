export enum MaterialController {
  MaterialBillOfMaterials = 'material-bill-of-materials',
  Supplier = 'supplier',
  FrameContract = 'frame-contract',
  StatisticOrder = 'statistic-order',
  Workflows = 'workflow-definition',
  DimensionType = 'dimension-type',
  ProductUnitOfMeasure = 'product-unit-of-measure',
  Shape = 'shape',
  OrderProposal = 'order-proposal',
  Buyer = 'buyer',
  Person = 'person',
  StockCard = 'stock-card',
  Stock = 'stock',
  Communication = 'communication',
  CommunicationChannel = 'communication-channel-type',
  CommunicationSubject = 'communication-subject-kind',
  CommunicationKind = 'communication-kind',
  ExternalDimensionType = 'external-dimension-type',
  Inquiry = 'inquiry',
  InquiryStatus = 'inquiry/status',
  EClass = 'eclass',
  EClassCategory = 'eclass-category',
  MeasurementUnit = 'measurement-unit',
  Currency = 'currency',
  DocumentTemplate = 'document-template',
  DocumentTemplatePurpose = 'document-template-purpose',
}
