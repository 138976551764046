import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiServiceName, ClientStorageController } from '@api/enums/controllers';
import { ApiService } from '@api/services/api.service';
import { BaseApiService } from '@api/services/base-api.service';
import { UserSettingModel } from '@core/api/models/client-storage/user-setting.model';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsDataService extends BaseApiService<ApiServiceName.ClientStorage, ClientStorageController.UserSettings> {
  constructor(
    override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.ClientStorage, ClientStorageController.UserSettings);
  }

  public getUserSettings(): Observable<UserSettingModel[]> {
    return this._httpClient.get<UserSettingModel[]>(`${this.baseUrl}`);
  }
}
