import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { CurrencyService } from '@services/currency.service';
import { CURRENT_CURRENCY_HEADER_NAME } from '@core/const/header-names';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class CurrencyInterceptor implements HttpInterceptor {
  constructor(private readonly _currencyService: CurrencyService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this._currencyService.currency.pipe(
      take(1),
      switchMap(currency =>
        next.handle(
          request.clone({
            headers: request.headers.append(CURRENT_CURRENCY_HEADER_NAME, currency),
          }),
        ),
      ),
    );
  }
}
