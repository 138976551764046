/**
 * Dostupné kontrolery mikroslužby TaskManager
 */
export enum TaskManagerController {
  Tasks = 'tasks',
  TasksWithNumbers = 'tasks-with-numbers',
  Users = 'users',
  WorkflowHistory = 'workflowhistory',
  Dashboards = 'dashboards',
  UserGroups = 'user-groups',
  WorkCoordination = 'work-coordination',
}
