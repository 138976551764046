/**
 * Dostupné kontrolery mikroslužby Documents
 */
export enum DocumentsController {
  Files = 'files',
  History = 'history',
  Types = 'types',
  Users = 'users',
  Pdf = 'files/pdf',
  Modules = 'modules',
}
