import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private readonly _appName: string = 'PPS G2';

  constructor(
    private readonly _router: Router,
    private readonly _translateService: TranslateService,
    private readonly _titleService: Title,
  ) {
    this.createTitle();
  }

  private get environmentTitleName(): string {
    let res = '';
    switch (environment.name) {
      case 'dev':
        res = '[DEV]';
        break;

      case 'stage':
        res = '[TEST]';
        break;

      default:
        res = '';
        break;
    }

    return res;
  }

  private createTitle(): void {
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._router.routerState.root),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data),
      )
      .subscribe(data => {
        let title = this._appName;
        title = this.environmentTitleName ? this.environmentTitleName + ' ' + title : title;
        const dataTitle = data['title'];

        if (dataTitle) {
          title += ' – ' + this._translateService.instant('common.nav.' + dataTitle);
        }

        this._titleService.setTitle(title);
      });

    this._titleService.setTitle(this._appName);
  }
}
