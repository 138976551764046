import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxLoadIndicatorModule } from 'devextreme-angular';

import { LoadIndicatorComponent } from '@shared/modules/load-indicator/load-indicator.component';

@NgModule({
  declarations: [LoadIndicatorComponent],
  imports: [CommonModule, DxLoadIndicatorModule],
  exports: [LoadIndicatorComponent],
})
export class LoadIndicatorModule {}
