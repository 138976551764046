import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environments/environment';

/**
 * Factory pro načítání překladů
 * @param http
 * @returns
 */
export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/old/', `.json?t=${environment.production ? environment.appVersion : Date.now()}`);
}
