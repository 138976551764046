export enum ExternalCooperationController {
  ExternalCooperationRequest = 'external-cooperation-request',
  ExternalCooperationRequestKind = 'external-cooperation-request-kind',
  ExternalCooperationItemType = 'external-cooperation-item-type',
  Batch = 'batch',
  ExternalCooperationRequestDeliveryType = 'delivery-type',
  ECAdminDocumentationGroup = 'documentation-group',
  ECAdminDocumentationKind = 'documentation-kind',
  Workflows = 'workflow-definition',
  DocumentationRule = 'documentation-rule',
  Supplier = 'supplier',
  ECAdminDeliveryPlace = 'place-of-delivery',
  Country = 'country',
  DocumentTemplate = 'document-template',
  DocumentTemplatePurpose = 'document-template-purpose',
  Inquiry = 'inquiry',
  CommunicationChannel = 'communication-channel-type',
  CommunicationSubject = 'communication-subject-kind',
  CommunicationKind = 'communication-kind',
  OrderProposal = 'order-proposal',
  Person = 'person',
  Currency = 'currency',
  EClass = 'eclass',
  EClassCategory = 'eclass-category',
  Purchaser = 'purchaser',
}
