export enum InputElement {
  SelectBox = 'DX-SELECT-BOX',
  TextBox = 'DX-TEXT-BOX',
  NumberBox = 'DX-NUMBER-BOX',
  DateBox = 'DX-DATE-BOX',
  Button = 'DX-BUTTON',
  Switch = 'DX-SWITCH',
  DropDownBox = 'DX-DROP-DOWN-BOX',
  DataGrid = 'DX-DATA-GRID',
  ValidationMessage = 'PPS-VALIDATION-MESSAGE',
  RadioGroup = 'DX-RADIO-GROUP',
  TextArea = 'DX-TEXT-AREA',
  PpsSwitch = 'PPS-SWITCH',
  PpsDropdown = 'PPS-DROPDOWN',
  PpsTextBox = 'PPS-TEXT-BOX',
  PpsNumberBox = 'PPS-NUMBER-BOX',
  PpsDateBox = 'PPS-DATE-BOX',
  PpsTextArea = 'PPS-TEXT-AREA',
  PpsFormField = 'PPS-FORM-FIELD',
  PpsMultiDropdown = 'PPS-MULTI-DROPDOWN',
}
