import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SplashScreenComponent } from '@core/modules/splash-screen/splash-screen.component';
import { LoadIndicatorModule } from '@shared/modules/load-indicator/load-indicator.module';

@NgModule({
  declarations: [SplashScreenComponent],
  imports: [CommonModule, TranslateModule, LoadIndicatorModule],
  exports: [SplashScreenComponent],
})
export class SplashScreenModule {}
