import { Dimensions } from '@shared/models/dimensions';

export class ImageThumbSize {
  public static readonly Small: Dimensions = {
    width: 80,
    height: 80,
  };
  public static readonly Medium: Dimensions = {
    width: 170,
    height: 170,
  };
  public static readonly Large: Dimensions = {
    width: 220,
    height: 220,
  };
}
