import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccessManagementController, ApiServiceName } from '@api/enums/controllers';
import { AssignedDataGroupsDataListModel, DataGroupsDataListModel } from '@api/models/access-management';
import { ApiService } from '@api/services/api.service';
import { BaseApiService } from '@api/services/base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DataGroupsDataService extends BaseApiService<ApiServiceName.AccessManagement, AccessManagementController.DataGroups> {
  constructor(
    override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.AccessManagement, AccessManagementController.DataGroups);
  }

  /**
   * Vrátit seznam skupin dat
   * @returns
   */
  public getDataGroups(): Observable<DataGroupsDataListModel> {
    return this._httpClient.get<DataGroupsDataListModel>(`${this.baseUrl}`);
  }

  /**
   * Vrátí přiřazené skupiny dat
   */
  public getAssignedDataGroups(): Observable<AssignedDataGroupsDataListModel> {
    return this._httpClient.get<AssignedDataGroupsDataListModel>(`${this.baseUrl}/assigned-data-groups`);
  }
}
