import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  RETURN_URL_LOCAL_STORAGE_KEY,
} from '@core/const/local-storage-keys';
import { UserDataGroupsModel } from '@core/modules/auth/models';
import { AuthModel } from '@core/modules/auth/models/auth.model';
import { UserModel } from '@core/modules/auth/models/user.model';
import { AuthHTTPService } from '@core/modules/auth/services/auth-http.service';
import { JwtService } from '@core/modules/auth/services/jwt.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // public fields
  public user$: Observable<UserModel | undefined>;
  public isLoading$: Observable<boolean>;
  private _isLoading: BehaviorSubject<boolean>;
  private readonly _authHttpService = inject(AuthHTTPService);

  constructor(
    private _jwtService: JwtService,
    private readonly _router: Router,
  ) {
    this._user = new BehaviorSubject<UserModel | undefined>(undefined);
    this._isLoading = new BehaviorSubject<boolean>(false);
    this.user$ = this._user.asObservable();
    this.isLoading$ = this._isLoading.asObservable();
  }

  private _user: BehaviorSubject<UserModel | undefined>;

  public get user(): UserModel | undefined {
    return this._user.value;
  }

  public login(returnUrl: string): void {
    // Zde se musí volat AccessManager napřímo, nesmí to jít přes API Management ani API Gateway (jsou tam nastaveny různé ochrany)
    this.setReturnUrlToLocalStorage(returnUrl);
    window.location.href = environment.signInUrl;
  }

  /**
   * Odhlášení
   * @param returnUrl
   */
  public logout(returnUrl?: string): void {
    this._authHttpService
      .logout()
      .pipe(
        finalize(() => {
          localStorage.clear();
          document.location.reload();
          this.clearSession();
          const queryParams = returnUrl ? { returnUrl: returnUrl } : {};

          this._router.navigate(['/auth/login'], {
            queryParams: queryParams,
          });
        }),
      )
      .subscribe();
  }

  /**
   * Vratí z local storage Auth tokeny
   */
  public getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const authData: AuthModel = new AuthModel({
        accessToken: localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY),
        refreshToken: localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY),
      });

      if (authData && !this._jwtService.isTokenExpired(authData.accessToken)) {
        return authData;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  /**
   * Vratí z local storage return url aplikace
   */
  public getReturnUrlFromLocalStorage(): string | null {
    return localStorage.getItem(RETURN_URL_LOCAL_STORAGE_KEY);
  }

  public initUser(dataGroups: UserDataGroupsModel[]): void {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.accessToken) {
      this.clearSession();
    }

    this._isLoading.next(true);
    const user = new UserModel({ ...this._jwtService.decodeToken(auth!.accessToken), ...auth, dataGroups: dataGroups });
    this._user.next(user);
    this._isLoading.next(false);
  }

  /**
   * Uloží do local storage Auth tokeny
   */
  public setAuthToLocalStorage(auth: AuthModel): void {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, auth.accessToken);
    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, auth.refreshToken);
  }

  /**
   * Vyčistí auth data
   */
  private clearSession(): void {
    this._user.next(undefined);
  }

  private setReturnUrlToLocalStorage(returnUrl: string): void {
    localStorage.setItem(RETURN_URL_LOCAL_STORAGE_KEY, returnUrl);
  }
}
