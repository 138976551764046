/**
 * Dostupné kontrolery mikroslužby Workflows
 */
export enum WorkflowManagementController {
  Workflows = 'workflows',
  WorkflowSteps = 'workflow-steps',
  SharedLimits = 'shared-limits',
  UserGroups = 'user-groups',
  WageClasses = 'wage-classes',
}
