import { DBConfig } from 'ngx-indexed-db';

export const PPS_INDEXED_DB_NAME = 'PPS_INDEXED_DB';
export const DATABASE_VERSION = 1;
export const GRID_STATES_STORE_NAME = 'GRID_STATES';

export const INDEXED_DB_CONFIG: DBConfig = {
  name: PPS_INDEXED_DB_NAME,
  version: DATABASE_VERSION,
  objectStoresMeta: [
    {
      store: GRID_STATES_STORE_NAME,
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'componentId', keypath: 'componentId', options: { unique: false } },
        { name: 'userId', keypath: 'userId', options: { unique: false } },
        { name: 'data', keypath: 'data', options: { unique: false } },
      ],
    },
  ],
};
