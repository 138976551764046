import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type SnackbarIconType = 'error' | 'success' | 'warning';

export interface SnackbarParams {
  id?: number;
  iconType?: SnackbarIconType;

  timeout?: number;
  message: string;
  messageParams?: Object;
  header?: string;
  headerParams?: Object;
  dismissible?: boolean;
  closeable?: boolean;
  dismissOnTimeout?: number | string;
  onDismiss?: Function;
  onAction?: Function;
  actionText?: string;
  actionTextParams?: Object;
  position?: 'top' | 'bottom';
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  public snackbars: any[] = [];
  public snackbarsBottom: any[] = [];
  public lastAlertId = 0;

  constructor(private readonly translateService: TranslateService) {}

  public addMessage(msg: SnackbarParams): SnackbarParams | undefined {
    if (msg.message) {
      const alert: SnackbarParams = {
        id: this.lastAlertId,
        message: msg.message,
        header: msg.header,
        iconType: msg.iconType || undefined,
        dismissible: typeof msg.dismissible !== 'undefined' ? msg.dismissible : true,
        closeable: typeof msg.closeable !== 'undefined' ? msg.closeable : true,
        dismissOnTimeout: msg.dismissOnTimeout || 5000,
        actionText: msg.actionText || this.translateService.instant('btn.close'),
        onAction: msg.onAction || undefined,
        position: msg.position || 'top',
      };

      this.lastAlertId++;

      if (alert.dismissible) {
        alert.timeout = window.setTimeout(
          () => {
            this.closeAlert(alert);
          },
          +(alert.dismissOnTimeout || 0),
        );
      }

      if (alert.position === 'top') {
        this.snackbars.push(alert);
      } else {
        this.snackbarsBottom.push(alert);
      }

      return alert;
    }
    return;
  }

  public addSnackbarAndTranslateIt(params: SnackbarParams): SnackbarParams | undefined {
    if (params.message) {
      params.message = this.translateService.instant(params.message, params.messageParams || undefined);
    }
    if (params.header) {
      params.header = this.translateService.instant(params.header, params.headerParams || undefined);
    }
    if (params.actionText) {
      params.actionText = this.translateService.instant(params.actionText, params.actionTextParams || undefined);
    }
    return this.addMessage(params);
  }

  public closeAlert(snackbar: SnackbarParams, manualClose = false) {
    if (snackbar.timeout) {
      clearTimeout(snackbar.timeout);
    }

    if (typeof snackbar.onDismiss === 'function' && !manualClose) {
      snackbar.onDismiss();
    }

    if (typeof snackbar.onAction === 'function' && manualClose) {
      snackbar.onAction();
    }

    let index = this.snackbars.findIndex(x => x.id === snackbar.id);
    if (index > -1) {
      this.snackbars.splice(index, 1);
    }
    index = this.snackbarsBottom.findIndex(x => x.id === snackbar.id);
    if (index > -1) {
      this.snackbarsBottom.splice(index, 1);
    }
  }
}
