import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeCz from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { interceptorProviders } from '@core/const/interceptor-providers';
import { appInitializerFactory } from '@core/factories/app-initializer.factory';
import { translateLoaderFactory } from '@core/factories/translate-loader.factory';
import { CustomMissingTranslationHandler } from '@core/handlers/custom-missing-translation.handler';
import { TokenService } from '@core/modules/auth/services/token.service';
import { SplashScreenModule } from '@core/modules/splash-screen/splash-screen.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslationService } from '@services/translation.service';
import { INDEXED_DB_CONFIG } from '@shared/grid-state-storing/indexed-db-config';
import { NgxIndexedDBModule } from 'ngx-indexed-db';

registerLocaleData(localeCz, 'cs-CZ');
registerLocaleData(localeEn, 'en-GB');
registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SplashScreenModule,
    NgxIndexedDBModule.forRoot(INDEXED_DB_CONFIG),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      deps: [TokenService],
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslationService) => {
        return translate.getSelectedLanguage().isoCode;
      },
      deps: [TranslationService],
    },
    interceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
