/**
 * Dostupné kontrolery mikroslužby Strojni zarizeni
 */
export enum ManufactoryControllerEnum {
  Anonymous = 'anonymous',
  Batches = 'batches',
  Calendars = 'calendars',
  Cart = 'cart',
  ControlTypes = 'control-types',
  CostCenters = 'cost-centers',
  Customers = 'customers',
  DailyPlans = 'daily-plans',
  DeliveryNotes = 'delivery-notes',
  FirstProductionOperations = 'first-production-operations',
  LogisticBoard = 'logistic-board',
  Machines = 'machines',
  MasterData = 'master-data',
  MaterialSheets = 'material-sheets',
  MaterialSheetsGroup = 'material-sheets-group',
  Operations = 'operations',
  Professions = 'professions',
  ProfessionGroups = 'profession-groups',
  ProfessionKinds = 'profession-kinds',
  ProfessionTypes = 'profession-types',
  RequestsForMaterial = 'requests-for-material',
  RequestsForMaterialDelivery = 'requests-for-material-delivery',
  ShiftSchedules = 'shift-schedules',
  ThnCalculations = 'thn-calculations',
  WageClasses = 'wage-classes',
  WarehouseMovements = 'warehouse-movements',
  WarehouseStocks = 'warehouse-stocks',
  Workflows = 'workflows',
  Workers = 'workers',
}
