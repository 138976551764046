import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/modules/auth';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          this._authService.logout(this._router.routerState.snapshot.url);
        }

        if (err.status === 403) {
          this._router.navigate(['/error/403'], {
            replaceUrl: true,
          });
        }

        if (!environment.production) {
          console.error(err);
        }

        return throwError(err);
      }),
    );
  }
}
