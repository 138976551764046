import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RequestProgressService } from '@services/request-progress.service';

@Injectable()
export class RequestInProgressInterceptor implements HttpInterceptor {
  private readonly _allowedMethods = ['post', 'put', 'delete'];

  constructor(private readonly _requestProgressService: RequestProgressService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isRequestMethodAllowed = this._allowedMethods.find(p => p === request.method.toLocaleLowerCase());
    if (isRequestMethodAllowed) {
      this._requestProgressService.changeIsInProgress(true);
      return next.handle(request).pipe(
        finalize(() => {
          this._requestProgressService.changeIsInProgress(false);
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
