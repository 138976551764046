import { inject, Injectable } from '@angular/core';
import { CreateWorkLogModel, ManufacturingOperationDetailModel, RecordType } from '@core/api/models/timesheets';
import { ManufacturingOperationDataService } from '@core/api/services/timesheets/manufacturing-operation-data.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { BehaviorSubject, map, Subject } from 'rxjs';

export interface TimeTrackerData {
  taskNumber?: string;
  start?: string;
  paused?: string;
  triggeredFrom?: string;
  meta?: Record<string, unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class TimeTrackerService {
  private readonly timesheetService = inject(ManufacturingOperationDataService);
  private readonly snackbarService = inject(SnackbarService);

  private taskNumber = new BehaviorSubject<string | null>(null);
  private operation = new BehaviorSubject<ManufacturingOperationDetailModel | null>(null);
  private trackerData = new BehaviorSubject<TimeTrackerData | null>(null);
  private timeElapsed = new Subject<number>();
  private openPopup = new Subject<void>();
  private onStart = new Subject<boolean>();
  private onPause = new Subject<void>();
  private onStop = new Subject<void>();

  public taskNumber$ = this.taskNumber.asObservable();
  public operation$ = this.operation.asObservable();
  public trackerData$ = this.trackerData.asObservable();
  public timeElapsed$ = this.timeElapsed.asObservable();
  public openPopup$ = this.openPopup.asObservable();
  public onStart$ = this.onStart.asObservable();
  public onPause$ = this.onPause.asObservable();
  public onStop$ = this.onStop.asObservable();

  public workInProgress = this.trackerData.pipe(map(d => !!d?.start && d?.paused !== 'true'));
  public workPaused = this.trackerData.pipe(map(d => d?.paused === 'true'));
  public isActive$ = this.trackerData.pipe(map(d => !!d));

  private readonly storageKey: string = 'time-tracker';
  private interval: any;

  constructor() {
    this.taskNumber.subscribe(taskNumber => {
      taskNumber && this.loadTask(taskNumber);
    });
    this.trackerData.subscribe(data => {
      this.taskNumber.next(data?.taskNumber ?? null);

      if (data?.start && data?.paused === 'false') {
        this.startInterval();
      } else {
        this.clearInterval();
      }
    });
    this.trackerData.next(this.getTrackerDataParsed());
  }

  startWork(taskNumber: string, triggeredFrom?: string, meta?: Record<string, any>) {
    this.taskNumber.next(taskNumber);
    this.saveTrackerData({ taskNumber, start: Date.now().toString(), paused: 'false', triggeredFrom, meta });
    setTimeout(() => {
      // need to wait before panel shown up to trigger open
      this.openPopup.next();
    }, 0);
    this.onStart.next(false);
  }

  continueWork() {
    if (!this.taskNumber.getValue()) return;
    this.saveTrackerData({
      ...this.trackerData.getValue(),
      taskNumber: this.taskNumber.getValue() as string,
      start: Date.now().toString(),
      paused: 'false',
    });
    this.onStart.next(true);
  }

  pauseWork() {
    this.operation.getValue() &&
      this.logWork(() => {
        this.saveTrackerData({ ...this.trackerData.getValue(), paused: 'true' });

        this.onPause.next();
      });
  }

  stopWork() {
    this.operation.getValue() &&
      this.logWork(() => {
        this.deteleTrackerData();

        this.onStop.next();
      });
  }

  revertWork() {
    this.saveTrackerData({ ...this.trackerData.getValue(), start: Date.now().toString(), paused: 'true' });
  }

  private startInterval() {
    this.interval = setInterval(() => {
      const start = this.trackerData.getValue()?.start;
      if (start) {
        const diff = this.calculateWorkTime(start, Date.now().toString());
        this.timeElapsed.next(diff);
      }
    }, 1000);
  }

  private clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.timeElapsed.next(0);
    }
  }

  setTaskNumber(taskNumber: string) {
    this.taskNumber.next(taskNumber);
  }

  private reloadTask() {
    const taskNumber = this.taskNumber.getValue();
    taskNumber && this.loadTask(taskNumber);
  }

  private loadTask(taskNumber: string) {
    this.timesheetService.getOperationByTaskNumber(taskNumber).subscribe((operation: ManufacturingOperationDetailModel) => {
      this.operation.next(operation);
    });
  }

  private logWork(cb: () => void) {
    const operation = this.operation.getValue();

    if (!operation) throw 'Operation is not loaded.';

    // TODO remove
    const durationInMinutes = this.calculateWorkTime(this.getTrackerDataParsed()?.start as string, Date.now().toString()) || 1;
    if (!durationInMinutes) {
      cb && cb();
      return;
    }

    const workLog = {
      durationInMinutes,
      recordType: RecordType.Claim,
    } as CreateWorkLogModel;
    this.timesheetService.createWorkLog(operation.operationClaim.id, workLog).subscribe({
      next: () => {
        cb && cb();
        this.snackbarService.addSnackbarAndTranslateIt({
          message: 'domains.timesheets.common.workLogged',
          iconType: 'success',
        });
      },
      error: () => {
        this.snackbarService.addSnackbarAndTranslateIt({
          message: 'domains.timesheets.errors.logWork',
          iconType: 'error',
        });
      },
    });
  }

  private saveTrackerData(data: TimeTrackerData) {
    const mergedData = { ...this.trackerData.getValue(), ...data };
    this.trackerData.next(mergedData);
    localStorage.setItem(this.storageKey, JSON.stringify(mergedData));
  }

  private deteleTrackerData() {
    this.reloadTask();
    localStorage.removeItem(this.storageKey);
    this.trackerData.next(null);
  }

  private getTrackerDataParsed() {
    try {
      const dataStr = localStorage.getItem(this.storageKey);
      if (dataStr) {
        const parsed = JSON.parse(dataStr) as TimeTrackerData;
        return parsed;
      }
      return null;
    } catch (ex) {
      return null;
    }
  }

  public calculateWorkTime(start: string, end: string) {
    const diff = Math.floor((new Date(+end).getTime() - new Date(+start).getTime()) / 1000 / 60);
    return diff;
  }
}
