import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ApiService } from '@api/services/api.service';
import { ApiDefinitionType } from '@api/types';
import { MOD_ACCESSIBLE_HEADER_NAME } from '@core/const/header-names';
import { filter, map, Observable } from 'rxjs';
import { AccessibleMode, AccessibleModeEntityModel } from '@api/models/shared';

/**
 * Base třída která sjednocuje základní prvky všech servis komunikující s API
 */
export abstract class BaseApiService<ServiceName extends keyof ApiDefinitionType, ControllerName extends ApiDefinitionType[ServiceName]> {
  constructor(
    protected readonly _apiService: ApiService,
    protected readonly _serviceName: ServiceName,
    protected readonly _controllerName: ControllerName,
  ) {}

  /**
   * Základní url obsahující url mikroslužby včetně kontroleru
   */
  public get baseUrl(): string {
    return this._apiService.getControllerApiUrl(this._serviceName, this._controllerName);
  }

  public createRequestWithAccessibleMode<T extends AccessibleModeEntityModel>(observable: Observable<HttpEvent<T>>): Observable<T | null> {
    return observable.pipe(
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((event: HttpEvent<T>) => {
        const response = event as HttpResponse<T>;
        const responseData = response.body;
        if (!responseData) return null;

        const mode = response.headers.get(MOD_ACCESSIBLE_HEADER_NAME);
        responseData.allowEdit = mode === AccessibleMode.Editable;

        return response.body;
      }),
    );
  }
}
