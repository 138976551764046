import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationProgressService {
  public isInProgress$: Observable<boolean>;
  private _isInProgress: BehaviorSubject<boolean>;

  constructor() {
    this._isInProgress = new BehaviorSubject<boolean>(false);
    this.isInProgress$ = this._isInProgress.asObservable();
  }

  public changeIsInProgress(isInProgress: boolean): void {
    this._isInProgress.next(isInProgress);
  }
}
