import { ApiServiceName } from '@api/enums/controllers';
import { ApiServiceVersionType } from '@api/types';

export const API_SERVICE_VERSION: ApiServiceVersionType = {
  [ApiServiceName.AccessManagement]: undefined,
  [ApiServiceName.ServiceSheets]: 'v2',
  [ApiServiceName.Documents]: 'v2',
  [ApiServiceName.Identity]: 'v2',
  [ApiServiceName.CapacityPlanning]: undefined,
  [ApiServiceName.ClientStorage]: undefined,
  [ApiServiceName.PersonalData]: undefined,
  [ApiServiceName.TaskManager]: undefined,
  [ApiServiceName.WorkflowManagement]: undefined,
  [ApiServiceName.Menu]: undefined,
  [ApiServiceName.ApiGateWay]: undefined,
  [ApiServiceName.NotifyCenter]: undefined,
  [ApiServiceName.ToolsManagementCatalog]: undefined,
  [ApiServiceName.ToolsManagementDispensing]: undefined,
  [ApiServiceName.LegacyConstruction]: undefined,
  [ApiServiceName.GlobalRecords]: undefined,
  [ApiServiceName.ToolsManagementWarehouse]: undefined,
  [ApiServiceName.Economics]: undefined,
  [ApiServiceName.Confluence]: undefined,
  [ApiServiceName.Emails]: undefined,
  [ApiServiceName.Construction]: undefined,
  [ApiServiceName.Planning]: undefined,
  [ApiServiceName.Timesheets]: 'v1',
  [ApiServiceName.Technology]: undefined,
  [ApiServiceName.Machinery]: undefined,
  [ApiServiceName.Manufactory]: undefined,
  [ApiServiceName.Material]: 'v1',
  [ApiServiceName.SupplierManagement]: 'v1',
  [ApiServiceName.ExternalCooperation]: 'v1',
  [ApiServiceName.Invoicing]: 'v1',
};
