/**
 * Dostupné kontrolery mikroslužby PeronsData
 */
export enum PersonalDataController {
  Persons = 'persons',
  Profile = 'profile',
  BusinessCard = 'business-card',
  CostCenters = 'cost-centers',
  Teams = 'teams',
}
