/**
 * Dostupné kontrolery mikroslužby ServiceSheets
 */
export enum ServiceSheetsController {
  Repairs = 'repairs',
  Reports = 'reports',
  CheckingFinds = 'checking-finds',
  Transports = 'transports',
  MoldingMachines = 'molding-machines',
  Listbooks = 'listbooks',
  RepairsScopes = 'repairs-scopes',
  ShapesImages = 'shapes-images',
  Shapes = 'shapes',
  Devices = 'devices',
  DeviceCards = 'devices/cards',
  TopPieces = 'top-pieces',
  Users = 'users',
  Workflows = 'workflows',
}
