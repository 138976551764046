export class SanitizationUtility {
  public static sanitize(input: string): string {
    return input
      .replace(/&/g, '__amp__')
      .replace(/</g, '__lt__')
      .replace(/>/g, '__gt__')
      .replace(/\u00A0/g, '__nbsp__'); // non-breaking space
  }

  public static desanitize(input: string): string {
    return input
      .replace(/__amp__/g, '&')
      .replace(/__lt__/g, '<')
      .replace(/__gt__/g, '>')
      .replace(/__nbsp__/g, '\u00A0'); // Non-breaking space
  }
}
