import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AUTHORIZATION_HEADER_NAME } from '@core/const/header-names';
import { AuthService } from '@core/modules/auth/services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly _authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth = this._authService.getAuthFromLocalStorage();

    request = request.clone({
      withCredentials: true,
    });

    if (auth && auth.accessToken) {
      request = request.clone({
        headers: request.headers.append(AUTHORIZATION_HEADER_NAME, `Bearer ${auth.accessToken}`),
      });
    }

    return next.handle(request);
  }
}
