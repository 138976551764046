import { ResolveFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { addModuleTranslation } from '@core/functions/add-module-translation.function';

export const translationResolver: ResolveFn<void> = (route, state) => {
  const translateService = inject(TranslateService);
  const httpClient = inject(HttpClient);
  return addModuleTranslation(translateService, httpClient, route.data['translatePath']);
};
