import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from '@core/modules/auth/interceptors/auth.interceptor';
import { RequestInProgressInterceptor } from '@core/interceptors/request-in-progress.interceptor';
import { LanguageInterceptor } from '@core/interceptors/language.interceptor';
import { CurrencyInterceptor } from '@core/interceptors/currency.interceptor';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { ValidationErrorInterceptor } from '@core/interceptors/validation-error.interceptor';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CurrencyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequestInProgressInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ValidationErrorInterceptor, multi: true },
];
