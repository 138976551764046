import { ElementRef, Injectable } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  private _el!: ElementRef;
  private _stopped!: boolean;

  constructor(private readonly _animationBuilder: AnimationBuilder) {}

  public init(element: ElementRef): void {
    this._el = element;
  }

  public hide(): void {
    if (this._stopped || !this._el) {
      return;
    }

    const player = this._animationBuilder
      .build([style({ opacity: '1' }), animate(800, style({ opacity: '0' }))])
      .create(this._el.nativeElement);

    player.onDone(() => {
      this._el.nativeElement.remove();
      this._stopped = true;
    });

    setTimeout(() => player.play(), 100);
  }
}
