import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UserViewSettingsService } from '@services/user-view-settings.service';
import { CURRENCY_LOCAL_STORAGE_KEY } from '@core/const/local-storage-keys';
import { Currency } from '@core/modules/layout/models';
import { Currency as CurrencyEnum } from '@api/models/shared/currency.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  public currencies: Currency[] = [
    {
      code: 'EUR',
      symbol: '€',
    },
    {
      code: 'CZK',
      symbol: 'Kč',
    },
  ];

  private _selectedCurrency!: Currency;
  private _defaultCurrency!: Currency;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _userViewSettingsService: UserViewSettingsService,
  ) {
    this.init();
    this._userViewSettingsService.userViewSettings$.pipe(filter(settings => settings !== undefined)).subscribe(() => {
      this.init();
    });
  }

  private _currency = new ReplaySubject<string>(1);

  /**
   * Currency observable getter
   */
  public get currency(): Observable<string> {
    return this._currency.asObservable();
  }

  /**
   * Nastaví menu
   */
  public setCurrency(code: string, saveCurrencyToLocalStorage: boolean): void {
    this.setSelectedCurrency(code, saveCurrencyToLocalStorage);
  }

  /**
   * Vrátí nastavenou menu
   */
  public getSelectedCurrency(): Currency {
    return this._selectedCurrency;
  }

  /**
   * Změna meny bez nutnosti reloadu
   * @param currency
   * @param saveCurrencyToLocalStorage
   */
  public setCurrencyWithoutRefresh(currency: string, saveCurrencyToLocalStorage: boolean) {
    this.setCurrency(currency, saveCurrencyToLocalStorage);
  }

  private init(): void {
    this.initDefaultCode();
    this.setSelectedCurrency(this.getInitSelectedCode(), false);
  }

  /**
   * Vrátí vybrana mena pro inicializaci
   * @returns
   */
  private getInitSelectedCode(): string {
    return localStorage.getItem(CURRENCY_LOCAL_STORAGE_KEY) || this._defaultCurrency?.code;
  }

  /**
   * Vratí výchozí menu
   * @returns
   */
  private initDefaultCode(): void {
    this._defaultCurrency = this.currencies.find(l => l.code === CurrencyEnum.Eur)!;
  }

  /**
   * Nastaví vybranou menu
   * Uloží do local storage
   * @param code
   * @param saveCurrencyToLocalStorage
   */
  private setSelectedCurrency(code: string, saveCurrencyToLocalStorage: boolean): void {
    const findCode = this.currencies.find(l => l.code === code);
    if (findCode) {
      this._selectedCurrency = findCode;
    } else {
      this._selectedCurrency = this._defaultCurrency;
    }

    this._currency.next(this._selectedCurrency.code);
    if (saveCurrencyToLocalStorage) {
      localStorage.setItem(CURRENCY_LOCAL_STORAGE_KEY, this._selectedCurrency.code);
    }
  }

  private getCurrencyByLanguage(language: string): string {
    let result: string;

    switch (language) {
      case 'cs':
        result = 'CZK';
        break;
      case 'en':
      case 'de':
      default:
        result = 'EUR';
        break;
    }

    return result;
  }
}
