export enum FormElementPermission {
  Deny = 0,
  Read = 1,
  Allow = 2,
}

export enum FormElementPermissionString {
  Deny = 'Deny',
  Read = 'Read',
  Allow = 'Allow',
}
