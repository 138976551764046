import { Injectable } from '@angular/core';
import { ApiServiceName } from '@api/enums/controllers';
import { ApiDefinitionType } from '@api/types';
import { API_SERVICE_VERSION } from '@core/const/api-service-version';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * Vrátí url adresu konkrétní API míkroslužby
   */
  public getServiceApiUrl<ServiceName extends keyof ApiDefinitionType>(serviceName: ServiceName): string {
    let result = this.getGatewayApiUrl();
    if (serviceName !== ApiServiceName.ApiGateWay) {
      result = this.getGatewayApiUrl() + '/' + serviceName;
    }

    return result;
  }

  /**
   * Vrátí url adresu Gateway API
   */
  public getGatewayApiUrl(): string {
    return environment.apiGwUrl;
  }

  /**
   * Vrátí kompletní url mikroslužby včetně kontroleru
   */
  public getControllerApiUrl<ServiceName extends keyof ApiDefinitionType>(
    serviceName: ServiceName,
    controllerName: ApiDefinitionType[ServiceName],
  ): string {
    let result = `${this.getServiceApiUrl(serviceName)}`;
    const serviceVersion = API_SERVICE_VERSION[serviceName];

    if (serviceVersion) {
      result += `/${serviceVersion}`;
    }

    result += `/${controllerName}`;

    return result;
  }
}
