import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { TranslationService } from '@services/translation.service';
import { translationResolver } from '@core/resolvers/translation.resolver';

export const cartTranslationResolver: ResolveFn<void> = (route, state) => {
  const translationService = inject(TranslationService);
  if (route.queryParams['language']) {
    translationService.setLanguageWithoutRefresh(route.queryParams['language'], true);
  }
  return translationResolver(route, state);
};
