import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CONTENT_LANGUAGE_HEADER_NAME } from '@core/const/header-names';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly _translationService: TranslateService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const lang = this._translationService.currentLang;
    if (lang) {
      request = request.clone({
        headers: request.headers.append(CONTENT_LANGUAGE_HEADER_NAME, lang),
      });
    }
    return next.handle(request);
  }
}
