import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import enMessages from 'devextreme/localization/messages/en.json';
import deMessages from 'devextreme/localization/messages/de.json';
import csMessages from 'devextreme/localization/messages/cs.json';
import { loadMessages, locale } from 'devextreme/localization';
import { Language } from '@core/modules/layout/models';
import { filter } from 'rxjs/operators';
import { LOCALIZATION_LOCAL_STORAGE_KEY } from '@core/const/local-storage-keys';
import { UserViewSettingsService } from '@services/user-view-settings.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public languages: Language[] = [
    {
      lang: 'cs',
      isoCode: 'cs-CZ',
      flag: './assets/media/svg/flags/149-czech-republic.svg',
    },
    {
      lang: 'en',
      isoCode: 'en-GB',
      flag: './assets/media/svg/flags/260-united-kingdom.svg',
    },
    {
      lang: 'de',
      isoCode: 'de-DE',
      flag: './assets/media/svg/flags/162-germany.svg',
    },
  ];

  private _selectedLanguage!: Language;
  private _defaultLanguage!: Language;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _userViewSettingsService: UserViewSettingsService,
  ) {
    this.init();
    this._userViewSettingsService.userViewSettings$.pipe(filter(settings => settings !== undefined)).subscribe(() => {
      this.init();
    });
  }

  /**
   * Nastaví jazyk
   */
  public setLanguage(lang: string, saveLanguageToLocalStorage: boolean): void {
    this.setSelectedLanguage(lang, saveLanguageToLocalStorage);
  }

  /**
   * Vrátí nastavený jazyk
   */
  public getSelectedLanguage(): Language {
    return this._selectedLanguage;
  }

  /**
   * Změna jazyka bez nutnosti reloadu
   * @param language
   */
  public setLanguageWithoutRefresh(language: string, saveLanguageToLocalStorage: boolean) {
    this._translateService.use(language);
    this.setLanguage(language, saveLanguageToLocalStorage);
  }

  public init(): void {
    this.initDefaultLang();
    this.setSelectedLanguage(this.getInitSelectedLang(), false);
    this._translateService.setDefaultLang(this._defaultLanguage.lang);
    this._translateService.use(this._selectedLanguage.lang);
    this.loadDevexpressMessages();
  }

  /**
   * Vrátí vybraný jazyk pro inicializaci
   * @returns
   */
  private getInitSelectedLang(): string {
    return localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) ?? this._translateService.getBrowserLang() ?? 'en';
  }

  /**
   * Vratí výchozí jazyk
   * @returns
   */
  private initDefaultLang(): void {
    // TODO může se načítat z konfigurace?
    this._defaultLanguage = this.languages.find(l => l.lang === 'en')!;
  }

  /**
   * Načte devexpress překlady
   */
  private loadDevexpressMessages() {
    switch (this._selectedLanguage.lang) {
      case 'cs':
        loadMessages(csMessages);
        break;
      case 'de':
        loadMessages(deMessages);
        break;
      default:
        loadMessages(enMessages);
        break;
    }

    locale(this._selectedLanguage.isoCode);
  }

  /**
   * Nastaví vybraný jazyk
   * Uloží do local storage
   * @param lang
   */
  private setSelectedLanguage(lang: string, saveLanguageToLocalStorage: boolean): void {
    const findLang = this.languages.find(l => l.lang === lang);
    if (findLang) {
      this._selectedLanguage = findLang;
    } else {
      this._selectedLanguage = this._defaultLanguage;
    }

    if (saveLanguageToLocalStorage) {
      localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, this._selectedLanguage.lang);
    }
  }
}
